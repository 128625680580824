import moment from 'moment';

export default class ContactViewModel {
  constructor(contact) {
    this.contact = contact
    if (contact) {
      this.fullName = `${contact.firstName} ${contact.lastName}`
      this.emailAddress = contact.emailAddress
    }
  }
}