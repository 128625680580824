import ZurichTime from './ZurichTimeFormat';

export default class AppointmentSlotViewModel {
  
  constructor(slotId, slot) {
    this.slotId = slotId;
    this.slot = slot;
    if (slot) {

      // Time and Day
      this.appointmentTime = `${ZurichTime.formattedTime(slot.start.toDate(),'HH:mm')} – ${ZurichTime.formattedTime(slot.end.toDate(),'HH:mm')}`;
      this.appointmentDay = `${ZurichTime.formattedTime(slot.start.toDate(), "dddd, Do MMMM YYYY")}`;

      // Location
      this.locationName = `${slot.location.name}`;
      this.locationStreet = `${slot.location.address.house.street} ${slot.location.address.house.number}`;
      this.locationArea = `${slot.location.address.zip.code} ${slot.location.address.zip.city}`;
      if (slot.location.directions) {
        this.locationMapURL = slot.location.directions.googleMapsLink;
      }
    }
  }
}