import moment from 'moment';

export default class ClientViewModel {
  constructor(client) {
    this.client = client
    if (client) {
      this.fullName = `${client.name.first} ${client.name.last}`
      this.emailAddress = client.email
    }
  }
}