import moment from 'moment';
import 'moment/locale/de';

export default class ZurichTime {

  static momentZh(date) {
    moment.locale('de');
    // Use UTC+2 for Zurich time zone
    return moment(date).utcOffset('+0200');
  }
  
  static formattedTime(date, format) {
    moment.locale('de');
    // Use UTC+2 for Zurich time zone
    return moment(date).utcOffset('+0200').format(format);
  }
}