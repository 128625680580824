import { db, firebase, firestoreTimestamp } from '../config/firebase';

import AppointmentSlotViewModel from './AppointmentSlotViewModel';
import ClientViewModel from './ClientViewModel';
import ContactViewModel from './ContactViewModel';

/* 
Client 
*/

export function readClients() {
    return db.collection('client').get();
}

export function onChangeClients(onChangesCallback) {
    db.collection('client')
        .onSnapshot(function (querySnapshot) {
            onChangesCallback();
        });
}

export function createClient(firstName, lastName, gender, email, phone, street, houseNumber, additionalAddress, city, zipCode, country, countryCode, insuranceNumber, onCreateCallback) {
    db.collection('client').add({
        name: {
            first: firstName,
            last: lastName,
        },
        address: {
            house: {
                street: street,
                number: houseNumber,
                additional: additionalAddress
            },
            zip: {
                code: zipCode,
                city: city
            },
            country: {
                code: countryCode,
                name: country
            }
        },
        gender: gender,
        email: email,
        phone: phone,
        insurance: insuranceNumber
    }).then(function (docRef) {
        onCreateCallback(docRef)
    })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}

export function updateClient(clientId, firstName, lastName, gender, email, phone, street, houseNumber, additionalAddress, city, zipCode, country, countryCode, insuranceNumber) {

    if (!clientId) {
        return
    }

    var clientRef = db.collection('client').doc(clientId);

    clientRef.set({
        name: {
            first: firstName,
            last: lastName,
        },
        address: {
            house: {
                street: street,
                number: houseNumber,
                additional: additionalAddress
            },
            zip: {
                code: zipCode,
                city: city
            },
            country: {
                code: countryCode,
                name: country
            }
        },
        gender: gender,
        email: email,
        phone: phone,
        insurance: insuranceNumber
    });
}

export function readClient(clientId, onReadCallback) {

    if (!clientId) {
        return
    }

    var clientRef = db.collection("client").doc(clientId);

    clientRef.get().then(function (doc) {
        if (doc.exists) {
            onReadCallback(doc.id, doc.data())
        } else {
            console.log("Database.readClient does not exist:", clientId);
        }
    }).catch(function (error) {
        console.log("Database.readClient error:", error);
    });
}

export function findClient(clientEmail, onExistsCallback, onNotExistsCallback) {

    if (!clientEmail) {
        return
    }

    var clientRef = db.collection("client").where("email", "==", clientEmail)
        .get()
        .then(function (querySnapshot) {
            if (querySnapshot.empty) {
                onNotExistsCallback()
            } else {
                var firstDoc = querySnapshot.docs[0]
                onExistsCallback(firstDoc.id, firstDoc.data())
            }
        })
        .catch(function (error) {
            console.log("Error getting documents: ", error);
        });
}


/*
Event
*/

export function readEvents() {
    return db.collection('event').get();
}

export function onChangeEvents(onCreateCallback, onDeleteCallback) {
    db.collection('event')
        .onSnapshot(function (snapshot) {
            snapshot.docChanges().forEach(function (change) {
                if (change.type === "added") {
                    onCreateCallback(change.doc)
                }
                if (change.type === "modified") {
                    // TODO
                }
                if (change.type === "removed") {
                    onDeleteCallback(change.doc)
                }
            });
        });
}

export function createEvent(start, end, summary, type, slotId, slot, locationId, clientId, client, onCreateCallback) {
    var startDate = firestoreTimestamp(start)
    var endDate = firestoreTimestamp(end)
    var clientRef = db.doc(`/client/${clientId}`)
    var locationRef = db.doc(`/location/${locationId}`)
    var slotRef = db.doc(`/slot/${slotId}`)

    db.collection('event').add({
        summary: summary,
        type: type,
        locationRef: locationRef,
        slotRef: slotRef,
        start: {
            date: startDate,
            zone: "CET"
        },
        end: {
            date:
                endDate,
            zone: "CET"
        },
        attendees: [
            clientRef
        ]
    }).then(function (docRef) {

        // Update slot
        updateAppointmentSlot(slotId, false, function (updatedSlotId) {

            // Notify client
            sendEventNotification(new ClientViewModel(client), new AppointmentSlotViewModel(slotId, slot))

            // Callbacl
            onCreateCallback(docRef)
        })
    })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}

export function readEvent(eventId, onReadCallback) {

    if (!eventId) {
        return
    }

    var eventRef = db.collection("event").doc(eventId);

    eventRef.get().then(function (doc) {
        if (doc.exists) {
            onReadCallback(doc.data())
        } else {
            console.log("Database.readEvent does not exist:", eventId);
        }
    }).catch(function (error) {
        console.log("Database.readEvent error:", error);
    });
}

export function deleteEvent(eventId, event, onDeleteCallback) {

    if (!eventId || !event) {
        return
    }

    var slotRef = event.slotRef

    db.collection("event").doc(eventId).delete().then(function () {
        updateAppointmentSlot(slotRef.id, true, function () {
            onDeleteCallback(eventId)
        })
    }).catch(function (error) {
        console.log("Database.deleteEvent error:", error);
    });
}

/* 
AppointmentSlot 
*/

export function readAppointmentSlots() {
    return db.collection('appointmentSlot').get();
}

export function readAppointmentSlotsBetweenDates(startDate, endDate, available) {
    const start = firestoreTimestamp(startDate)
    const end = firestoreTimestamp(endDate)
    return db.collection('appointmentSlot').where("available", "==", available).where("start", ">", start).where("start", "<", end).get();
}

export function onChangeAppointmentSlots(onCreateCallback, onUpdateCallback, onDeleteCallback) {
    db.collection('appointmentSlot')
        .onSnapshot(function (snapshot) {
            snapshot.docChanges().forEach(function (change) {
                if (change.type === "added") {
                    console.log("New slot: ", change.doc.data());
                    onCreateCallback(change.doc)
                }
                if (change.type === "modified") {
                    console.log("Modified slot: ", change.doc.data());
                    onUpdateCallback(change.doc)
                }
                if (change.type === "removed") {
                    console.log("Removed slot: ", change.doc.data());
                    onDeleteCallback(change.doc)
                }
            });
        });
}

export function readAppointmentSlot(slotId, onReadCallback) {

    if (!slotId) {
        return
    }

    var slotRef = db.collection("appointmentSlot").doc(slotId);

    slotRef.get().then(function (doc) {
        if (doc.exists) {
            onReadCallback(doc.data())
        } else {
            console.log("Database.readAppointmentSlot does not exist:", slotId);
        }
    }).catch(function (error) {
        console.log("Database.readAppointmentSlot error:", error);
    });
}

export function deleteAppointmentSlot(slotId, onDeleteCallback) {

    if (!slotId) {
        return
    }

    db.collection("appointmentSlot").doc(slotId).delete().then(function () {
        onDeleteCallback()
    }).catch(function (error) {
        console.log("Database.deleteAppointmentSlot error:", error);
    });
}

export function createAppointmentSlot(start, end, therapistId, locationId, location) {
    var startDate = firestoreTimestamp(start)
    var endDate = firestoreTimestamp(end)
    var therapistRef = db.doc(`/therapist/${therapistId}`)
    var locationRef = db.doc(`/location/${locationId}`)
    db.collection('appointmentSlot').add({
        locationRef: locationRef,
        location: location,
        start: startDate,
        end: endDate,
        therapist: therapistRef,
        available: true // Default value when creating a new slot
    })
}

export function updateAppointmentSlot(slotId, available, onUpdateCallback) {

    if (!slotId) {
        return
    }

    var slotRef = db.collection("appointmentSlot").doc(slotId);

    slotRef.update({
        available: available
    }).then(function () {
        onUpdateCallback(slotId)
    }).catch(function (error) {
        console.log("Database.updateAppointmentSlot error:", error);
    });
}

/*
Appointment Request
*/

export function readAppointmentRequests() {
    return db.collection('appointmentRequest').get();
}

export function createAppointmentRequest(slotId, slot, contact, onCreateCallback) {

    var slotRef = db.collection("appointmentSlot").doc(slotId);

    db.collection('appointmentRequest').add({
        slotRef: slotRef,
        slot: slot,
        contact: contact
    })
    .then(function (docRef) {

         // Send notification to customer
        sendAppointmentRequestNotification(new ContactViewModel(contact), new AppointmentSlotViewModel(slotId, slot))

        // Modify appointment slot so it's not available anymore
        updateAppointmentSlot(slotId, false, function (updatedSlotId) {
            onCreateCallback(docRef)
        })

    })
    .catch(function (error) {
        console.error("Error adding document: ", error);
    });
}

export function cancelAppointmentRequest(requestId, request, onDeleteCallback) {

    if (!requestId) {
        return
    }

    var slotRef = request.slotRef

    db.collection("appointmentRequest").doc(requestId).delete().then(function () {
        updateAppointmentSlot(slotRef.id, true, function () {
            onDeleteCallback(requestId)
        })
    }).catch(function (error) {
        console.log("Database.cancelAppointmentRequest error:", error);
    });
}

export function confirmAppointmentRequest(requestId, request, onDeleteCallback) {

    if (!requestId) {
        return
    }

    var slotRef = request.slotRef

    db.collection("appointmentRequest").doc(requestId).delete().then(function () {

        // Callback
        onDeleteCallback(requestId)

    }).catch(function (error) {
        console.log("Database.confirmAppointmentRequest error:", error);
    });
}

export function onChangeAppointmentRequests(onCreateCallback, onDeleteCallback) {
    db.collection('appointmentRequest')
        .onSnapshot(function (snapshot) {
            snapshot.docChanges().forEach(function (change) {
                if (change.type === "added") {
                    onCreateCallback(change.doc)
                }
                if (change.type === "modified") {
                    console.log("Modified slot: ", change.doc.data());
                }
                if (change.type === "removed") {
                    onDeleteCallback(change.doc.id)
                }
            });
        });
}

/* 
Location 
*/

export function readLocation(locationId, onReadCallback) {

    if (!locationId) {
        return
    }

    var locationRef = db.collection("location").doc(locationId);

    locationRef.get().then(function (doc) {
        if (doc.exists) {
            onReadCallback(doc.data())
        } else {
            console.log("Database.readLocation does not exist:", locationId);
        }
    }).catch(function (error) {
        console.log("Database.readLocation error:", error);
    });
}

export function readLocations() {
    return db.collection('location').get();
}

/*
Email Notifications
*/

export function sendAppointmentRequestNotification(contactViewModel, slotViewModel) {

    if (!contactViewModel || !slotViewModel) {
        return
    }

    // Email
    var subject = `[Appointment Request] ${contactViewModel.fullName} on ${slotViewModel.appointmentDay} (${slotViewModel.locationName})`
    var html = `<h1>Appointment Details</h1><h3>Client Information</h3><p>${contactViewModel.fullName}</p><p>${contactViewModel.emailAddress}</p><h3>Location</h3><p>${slotViewModel.locationName}</p><h3>Time</h3><p>${slotViewModel.appointmentTime}</p><a href="https://sibylleeckert.ch/admin">Confirm Appointment Request</a>`

    sendEmailNotification("info@sibylleeckert.ch", subject, "", html)
}

export function sendEventNotification(clientViewModel, slotViewModel) {

    if (!clientViewModel || !slotViewModel) {
        return
    }

    // Email
    var subject = `Your appointment with Sibylle on ${slotViewModel.appointmentDay} is confirmed`
    var html = `<h1>Appointment Details</h1><h3>Time</h3><p>${slotViewModel.appointmentDay}</p><p>${slotViewModel.appointmentTime}</p><h3>Location</h3><p>${slotViewModel.locationName}</p><p>${slotViewModel.locationStreet}</p><p>${slotViewModel.locationArea}</p><p><a href="${slotViewModel.locationMapURL}">Directions</a></p><p><a href="#">Add to calendar</a></p>`

    sendEmailNotification(clientViewModel.emailAddress, subject, "", html)
}

export function sendEmailNotification(to, subject, text, html) {

    if (to) {
        db.collection('mail').add({
            to: to,
            message: {
                subject: subject,
                text: text,
                html: html,
            }
        })
        .then(function (docRef) {
            console.error("Success email notification: ", docRef);
        })
        .catch(function (error) {
            console.error("Error email notification: ", error);
        });
    }
}
